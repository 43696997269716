import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@15.0.0_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@18._7sn42tuf7z5eocgdis2vn3jsxe/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["SkeletonTheme"] */ "/vercel/path0/node_modules/.pnpm/react-loading-skeleton@3.5.0_react@18.3.1/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-loading-skeleton@3.5.0_react@18.3.1/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager", webpackExports: ["SignOut","SignInWithFaceit"] */ "/vercel/path0/src/components/actions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/NewsPopup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/NextImageWithFallback.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContextProvider"] */ "/vercel/path0/src/contexts/AuthContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FaceitEdgeContextProvider"] */ "/vercel/path0/src/contexts/FaceitEdgeContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
